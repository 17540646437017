import Photo from '@/components/atoms/Image/Image';
import { TeaserAspectRatio, TeaserImage, TeaserType, ImageProps as Improps } from '@/ts';
import { ContentType } from '@/ts/types/contentTypes';
import getTeaserIndicators from '@/helpers/utils/teaser';
import ImagePlaceholder from './ImagePlaceholder';
import Picture from '../Picture/Picture';
import Indicators from './Indicators/Indicators';

interface ImageProps {
  contentType: ContentType;
  teaserType: TeaserType;
  lastUpdated: string;
  imageCollection: TeaserImage | null;
  placeholderImageUrl?: string;
  scaleImageWidth?: string;
  aspectRatio?: TeaserAspectRatio;
  publication?: string;
  hasVideo?: boolean;
  imageCount?: string;
  teaserSize?: string;
}

const Image: React.FC<ImageProps> = ({
  imageCollection,
  aspectRatio,
  scaleImageWidth,
  placeholderImageUrl,
  publication,
  hasVideo,
  teaserType,
  contentType,
  imageCount,
  lastUpdated,
  teaserSize,
}) => {
  const { showLiveIndicator, showVideoIcon, showPhotoIcon } = getTeaserIndicators(contentType, teaserType, lastUpdated);
  const { standardTeaser, standardTeaserPrimaryLarge } = imageCollection || {};
  const shouldShowPlaceholder =
    !imageCollection ||
    (teaserSize !== 'large' && !standardTeaser?.src) ||
    (teaserSize === 'large' && !standardTeaserPrimaryLarge?.src);

  if (shouldShowPlaceholder) {
    return (
      <ImagePlaceholder
        aspectRatio={aspectRatio}
        scaleImageWidth={scaleImageWidth}
        placeholderImageUrl={placeholderImageUrl}
        publication={publication}
        teaserSize={teaserSize}
        teaserType={teaserType}
      />
    );
  }

  return (
    <>
      {teaserSize !== 'large' && standardTeaser?.src ? (
        <Photo {...(standardTeaser as Improps)} alt="" />
      ) : (
        <Picture
          picture={standardTeaserPrimaryLarge}
          publication={publication}
          scaleImageWidth={scaleImageWidth}
          placeholderImageUrl={placeholderImageUrl}
          showLiveIndicator={showLiveIndicator}
          teaserType={teaserType}
          teaserSize={teaserSize}
        />
      )}

      <Indicators
        showPhotoIcon={showPhotoIcon}
        showLiveIndicator={showLiveIndicator}
        showVideoIcon={showVideoIcon}
        imageCount={imageCount}
        hasVideo={hasVideo}
      />
    </>
  );
};

export default Image;
