import { TeaserProps } from '@/ts/interfaces';
import getTeaserIndicators from '@/helpers/utils/teaser';
import getDataTmdatatrackSource from '@/helpers/utils/getDataTmdatatrackSource';
import Footer from '../Footer/Footer';
import Heading from '../Heading/Heading';
import Picture from '../Picture/Picture';
import styles from './WOBTeaser.module.scss';

const WOBTeaser: React.FC<TeaserProps> = ({
  AnalyticData,
  as: TeaserHeadline = 'h2',
  aspectRatio,
  configData,
  data,
  dataTmdatatrack,
  dataTmdatatrackIndex,
  enableAnalytics = false,
  eventName,
  index,
  placeholderImageUrl,
  publication,
  scaleImageWidth = 'initial',
  teaserSize,
  teaserType,
}) => {
  const {
    attributes,
    contentType,
    dates,
    headline,
    highlightPhrase,
    id,
    images,
    platform,
    primaryTag,
    settings,
    source,
    url,
  } = data;
  const { showLiveIndicator } = getTeaserIndicators(contentType.type, teaserType, dates?.updated);
  const dataTmdatatrackSource = getDataTmdatatrackSource(source, attributes);

  const dataAttributes = {
    'data-tmdatatrack': dataTmdatatrack,
    'data-tmdatatrack-articleid': id,
    'data-tmdatatrack-index': dataTmdatatrackIndex || index,
    'data-tmdatatrack-platform': platform,
    'data-tmdatatrack-source': dataTmdatatrackSource,
  };

  return (
    <article className={styles[`teaser-${teaserType}`]} {...dataAttributes}>
      <div className={styles['image-box']}>
        <div className={styles.overlay}> </div>
        <Picture
          {...{
            picture: images?.wobTeaser,
            publication,
            aspectRatio,
            scaleImageWidth,
            placeholderImageUrl,
            showLiveIndicator,
            teaserType,
            teaserSize,
          }}
        />
      </div>
      <div className={styles.content}>
        <Heading
          TeaserHeadline={TeaserHeadline}
          url={url}
          headline={headline}
          highlightPhrase={highlightPhrase}
          highlightedPhraseEnabled={configData?.features?.highlightedPhraseEnabled}
          publication={publication}
          contentType={contentType?.type}
          contentSubType={contentType?.subType}
          teaserType={teaserType}
          eventName={eventName}
          enableAnalytics={enableAnalytics}
          AnalyticData={AnalyticData}
          settings={settings}
        />

        <Footer tagUrl={primaryTag?.tagUrl} tagLabel={primaryTag?.tagName} AnalyticData={AnalyticData} />
      </div>
    </article>
  );
};

export default WOBTeaser;
