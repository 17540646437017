import styles from './styles/HighlightedPhrase.module.scss';

const HighlightedPhrase = ({
  fullText,
  highlightedText,
  highlightClass,
  highlightedPhraseEnabled,
}: {
  fullText: string;
  highlightedText?: string;
  highlightClass?: string;
  highlightedPhraseEnabled?: boolean;
}): JSX.Element => {
  if (
    !highlightedPhraseEnabled ||
    !highlightedText ||
    !fullText.toLocaleLowerCase().includes(highlightedText.toLocaleLowerCase())
  ) {
    return <>{fullText}</>;
  }

  const regex = new RegExp(`(${highlightedText.toLocaleLowerCase()})`, 'gi');

  return (
    <>
      {fullText.split(regex).map((item, index) => {
        if (regex.test(item.toLocaleLowerCase())) {
          return (
            <span
              data-testid="highlight-span"
              key={index}
              className={`${highlightClass ? `${styles[highlightClass]} ` : ''}${styles['highlight-color']}`}
            >
              {item}
            </span>
          );
        }

        return item;
      })}
    </>
  );
};

export default HighlightedPhrase;
