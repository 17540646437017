import Image from '@/components/atoms/Image/Image';
import Label from '@/components/atoms/Label/Label';
import { ImageProps, TeaserPictureProps } from '@/ts';
import ImagePlaceholder from '../Image/ImagePlaceholder';

const Picture: React.FC<TeaserPictureProps> = ({
  picture = { sources: [], src: '' },
  scaleImageWidth,
  placeholderImageUrl,
  publication,
  showLiveIndicator,
  teaserType,
  teaserSize,
}) => {
  const validSources = picture?.sources?.filter((source) => Boolean(source?.srcSet?.trim()));

  return picture?.src ? (
    <picture>
      {validSources &&
        validSources.map(({ srcSet, media }, index) => (
          <source key={index} srcSet={srcSet} media={media} data-testid="source-element" />
        ))}
      {<Image {...(picture as ImageProps)} alt="" />}
      {showLiveIndicator && <Label text="LIVE" blinker />}
    </picture>
  ) : (
    <ImagePlaceholder
      scaleImageWidth={scaleImageWidth}
      placeholderImageUrl={placeholderImageUrl}
      publication={publication}
      teaserSize={teaserSize}
      teaserType={teaserType}
    />
  );
};

export default Picture;
