import { TeaserFooterProps } from '@/ts';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import styles from './Footer.module.scss';

const Footer: React.FC<TeaserFooterProps> = ({ tagLabel, tagUrl, AnalyticData }) => (
  <footer className={styles['additional-content']}>
    <a href={tagUrl} aria-label={tagLabel}>
      <AnalyticsTrack
        analyticsData={[
          'TEASER:TAG',
          {
            ...AnalyticData,
            componentText: `${AnalyticData && AnalyticData.linkDomain}${tagUrl}`,
            componentTitle: tagLabel,
          },
        ]}
      >
        {tagLabel}
      </AnalyticsTrack>
    </a>
  </footer>
);

export default Footer;
