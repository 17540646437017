import publicFolder from '@/helpers/utils/publicFolder';
import { mapClassNames } from '@/helpers/utils/classNames';
import { TeaserAspectRatio } from '@/ts';
import styles from './styles/ImagePlaceholder.module.scss';

type ImagePlaceholderProps = {
  placeholderImageUrl?: string;
  scaleImageWidth?: string;
  aspectRatio?: TeaserAspectRatio;
  publication?: string;
  teaserType: string;
  teaserSize: string | undefined;
};

const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({
  aspectRatio,
  scaleImageWidth,
  placeholderImageUrl,
  publication,
  teaserType,
  teaserSize,
}) => {
  const placeholderAspectRatioClass =
    teaserType === 'primary'
      ? `placeholder-aspect-ratio-${teaserType}-${teaserSize}`
      : `placeholder-aspect-ratio-${teaserType}`;
  const backgroundImageUrl = placeholderImageUrl || `/${publicFolder}/masthead-${publication}-default.svg`;
  const nonCuratedImagePlaceHolderAspectRatioClassName = `placeholder-aspect-ratio-${aspectRatio?.replace(
    /\s*\/\s*/g,
    '-'
  )}`;

  return (
    <div
      data-testid="placeholder-image"
      className={mapClassNames(
        styles,
        'placeholder-image',
        placeholderAspectRatioClass,
        nonCuratedImagePlaceHolderAspectRatioClassName,
        scaleImageWidth && `scale-image-${scaleImageWidth}`
      )}
      style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
    />
  );
};
export default ImagePlaceholder;
