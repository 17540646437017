import Label from '@/components/atoms/Label/Label';
import { mapClassNames } from '@/helpers/utils/classNames';
import styles from './styles/Indicators.module.scss';

const Indicators: React.FC<{
  showPhotoIcon: boolean;
  showVideoIcon: boolean;
  showLiveIndicator: boolean;
  imageCount?: string;
  hasVideo?: boolean;
}> = ({ showPhotoIcon, imageCount, hasVideo, showVideoIcon, showLiveIndicator }) => {
  const isMedia = showPhotoIcon || (!!hasVideo && showVideoIcon);

  return (
    <>
      {isMedia && (
        <div className={mapClassNames(styles, 'indicators', 'media')}>
          {showPhotoIcon && <Label text={imageCount} icon="camera" />}
          {hasVideo && showVideoIcon && <Label icon="play" rounded />}
        </div>
      )}
      {showLiveIndicator && (
        <div className={mapClassNames(styles, 'indicators', 'live')}>
          <Label text="LIVE" blinker />
        </div>
      )}
    </>
  );
};

export default Indicators;
